<script>
import { api } from "@/state/services";

export default {
  props: {
    id: {
      default: ""
    },
    quantity: {
      default: ""
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      qty: this.$props.quantity,
    };
  },
  methods: {
    buyProduct() {
      if (this.qty != this.$props.quantity) {
        this.loading = true

        api
          .post('franchise/inventory/cart', {
            id: this.$props.id,
            qty: this.qty
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.$toast.success('A quantidade foi atualizada com sucesso.')
              this.$parent.$parent.getCart()
            } else {
              this.qty = this.$props.quantity
              this.$toast.error(response.data.message)
            }

            this.loading = false
          })
          .catch(error => {
            if (error) {
              this.$toast.error('Não foi possível adicionar o produto em seu carrinho, tente novamente.')
            }
          })
      }
    },
    editItem(id, qty) {
      if (id) {
        api
          .post('franchise/inventory/cart', {
            id: id,
            qty: qty
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.$parent.$parent.getCart()
            }
          })
      }
    },
  }
};
</script>

<template>
  <div>
    <b-form @submit.prevent="buyProduct()" style="width:100px;" class="m-auto">
      <b-form-group class="m-0" label-for="qty">
        <b-input-group>
          <b-form-input class="text-center" v-model="qty" type="text" inputmode="numeric" v-mask="'###'" autocomplete="off"></b-form-input>
          <b-input-group-append>
            <b-button v-if="loading" style="width:40px;" class="btn-block font-size-20 py-0 px-2 text-center" type="submit" variant="secondary"><b-spinner small class="align-top mt-2" variant="white"
                role="status"></b-spinner></b-button>
            <b-button v-else style="width:40px;" class="btn-block font-size-20 py-0 px-2 text-center" type="submit" variant="secondary"><i class="bx bx-analyse align-top mt-1"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
    <button class="btn btn-link p-0 text-dark font-size-12" v-on:click="editItem(id, 0)">remover</button>
  </div>
</template>